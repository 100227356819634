import { fromJS } from 'immutable';
import {
  createAction,
  handleActions,
} from 'redux-actions';

const PREFIX = 'CREATE_PROJECT_MENU';
export const createProjectMenuOpenAction = createAction(`${PREFIX}/OPEN`);
export const createProjectMenuCloseAction = createAction(`${PREFIX}/CLOSE`);

const initialState = fromJS({
  open: false,
});

const reducer = handleActions({
  [createProjectMenuOpenAction]: state =>
    state.set('open', true),
  [createProjectMenuCloseAction]: state =>
    state.set('open', false),
}, initialState);

export default reducer;
