import { takeLatest } from 'redux-saga/effects';

import {
  getMyProjectManagementDataAction,
  removeSetManagementDataItemAction,
  selectSetManagementDataItemForAnalysisAction,
  duplicateSetsAction,
  getSetForFilteringAction,
  downloadMyProjectDataAction,
  myProjectManagementSearchRedirectAction,
} from '../reducer';

import { getSetManagementDataWorker } from './workers/getSetManagementDataWorker';
import { removeSetManagementDataItemWorker } from './workers/removeSetManagementDataItemWorker';
import { selectSetManagementDataItemForAnalysisWorker } from './workers/selectSetManagementDataItemForAnalysisWorker';
import { duplicateSetManagementWorker } from './workers/duplicateSetManagement';
import { getSetForFilteringWorker } from './workers/getSetForFiltering';
import { downloadMyProjectWorker } from './workers/downloadMyProject';
import { myProjectManagementSearchRedirectWorker } from './workers/myProjectManagementSearchRedirectWorker';

function* myProjectsManagementSaga() {
  yield takeLatest(getMyProjectManagementDataAction, getSetManagementDataWorker);
  yield takeLatest(removeSetManagementDataItemAction, removeSetManagementDataItemWorker);
  yield takeLatest(selectSetManagementDataItemForAnalysisAction, selectSetManagementDataItemForAnalysisWorker);
  yield takeLatest(duplicateSetsAction, duplicateSetManagementWorker);
  yield takeLatest(getSetForFilteringAction, getSetForFilteringWorker);
  yield takeLatest(downloadMyProjectDataAction, downloadMyProjectWorker);
  yield takeLatest(myProjectManagementSearchRedirectAction, myProjectManagementSearchRedirectWorker);
}

export default myProjectsManagementSaga;
