import axios from 'axios';
import Storage from '../components/auth/Storage/Storage';
import { tokenExpiredAction } from '../components/auth/reducer';
import { API_URL, CUSTOMER } from '../settings';

const defineAPI = () => {
  console.log(`API: ${API_URL}`);// eslint-disable-line no-console
  console.log(`customer: ${CUSTOMER}`);// eslint-disable-line no-console
  return API_URL;
};

let store = null;

const API = defineAPI();

const getToken = () => (Storage.getValue('user') ? Storage.getValue('user').token : 's');
const CONTENT_TYPE = 'application/json;charset=UTF-8';

const settings = {
  baseURL: API,
  headers: { 'x-token': getToken() },
  validateStatus: (status) => {
    if (status === 511 || status === 401) {
      store.dispatch(tokenExpiredAction());
    }
    return status >= 200 && status < 300; // default values
  },
};

let axiosInstance = axios.create(settings);

axios.defaults.headers.post['Content-Type'] = CONTENT_TYPE;

axiosInstance.interceptors.response.use(response => response, error => Promise.reject(error));

const Api = {
  setToken: (token) => {
    axiosInstance = axios.create(Object.assign(settings, { headers: { 'x-token': token } }));
  },
  setStore: (reduxStore) => {
    store = reduxStore;
  },
  loginUser: data => (
    axiosInstance({
      method: 'post',
      url: 'login/authenticate',
      data,
    })
  ),
  logoutUser: () => (
    axiosInstance({
      method: 'get',
      url: 'logout',
    })
  ),
  checkForgotPasswordToken: ({ token }) => (
    axiosInstance({
      method: 'get',
      url: `user/forgot-password/${token}`,
    })
  ),
  restorePassword: ({ email }) => (
    axiosInstance({
      method: 'get',
      url: 'user/forgot-password',
      params: { email },
    })
  ),
  setNewPassword: data => (
    axiosInstance({
      method: 'post',
      url: '/user/forgot-password/update',
      data,
    })
  ),
  checkActivateAccountToken: ({ token }) => (
    axiosInstance({
      method: 'get',
      url: `user/activate-account/${token}`,
    })
  ),
  activateAccount: ({ token, userDto }) => (
    axiosInstance({
      method: 'post',
      url: `/user/activate-account/${token}`,
      data: userDto,
    })
  ),
  changePassword: data => (
    axiosInstance({
      method: 'post',
      url: 'user/updatePassword',
      data,
    })
  ),
  currentUser: () => (
    axiosInstance({
      method: 'get',
      url: 'user/getCurrentUser',
    })
  ),
  userAddOption: data => (
    axiosInstance({
      method: 'put',
      url: 'user/options/add',
      params: data,
    })
  ),
  getSecretCode: () => (
    axiosInstance({
      method: 'get',
      url: 'user/generate-secret',
    })
  ),
  checkSecretCode: data => (
    axiosInstance({
      method: 'get',
      url: 'user/check-totp-code',
      params: data,
    })),
  update2FAAuth: data => (
    axiosInstance({
      method: 'put',
      url: 'user/update-two-factor-auth',
      data,
    })
  ),
  getAllConceptTypes: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/savedlists/get-all-list-types',
    })
  ),
  findConcepts: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/savedlists/find-concepts',
      params: data,
    })
  ),
  findConceptBySemanticCategory: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/data/searchTermInSemanticCategory',
      params: data,
    })
  ),
  searchTerm: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/data/searchTerm',
      params: data,
    })
  ),
  searchDiseases: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/data/searchDiseases',
      params: data,
    })
  ),
  duplicateRelationMapManagementItem: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/project-relation-map/duplicate',
      params: data,
    })
  ),
  duplicateRelationMapManagementItemV2: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/project-relation-map/duplicate/v2',
      params: data,
    })
  ),
  editSet: data => (
    axiosInstance({
      method: 'put',
      url: 'internal/cerebrum/savedlists/save-users-list',
      data,
    })
  ),
  deleteSets: data => (
    axiosInstance({
      method: 'delete',
      url: 'internal/cerebrum/advanced-set-management/',
      data,
    })
  ),
  uploadFile: ({ formData, params }) => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/advanced-set-management/parse-file',
      headers: {
        'x-token': getToken(),
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      params,
      paramsSerializer: {
        indexes: null,
      }
    })
  ),
  uploadProjectFile: ({ projectId, formData, params }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/project/${projectId}/advanced-set/parse-file`,
      headers: {
        'x-token': getToken(),
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
      params,
      paramsSerializer: {
        indexes: null,
      }
    })
  ),
  addSet: data => (
    axiosInstance({
      method: 'put',
      url: 'internal/cerebrum/savedlists/save-users-list',
      data,
    })
  ),
  findRelatedAnalysis: data => (
    axiosInstance({
      method: 'post',
      url: `internal/workflow/set-analysis/analysis/${data.analysisMethod}`,
      data: {
        conceptGis: data.concepts,
        selectedSemanticType: data.type,
        backgroundSetConfiguration: data.backgroundSetConfiguration,
      },
      params: data.params,
    })
  ),
  regulationAnalysis: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/expression-regulation-candidates',
      data: {
        conceptGis: data.concepts,
        backgroundSetConfiguration: data.backgroundSetConfiguration,
      },
      params: data.params,
    })
  ),
  proteinAnalysis: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/protein-complex-candidates',
      data: {
        conceptGis: data.concepts,
        backgroundSetConfiguration: data.backgroundSetConfiguration,
      },
      params: data.params,
    })
  ),
  tissue: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/tissue/normal',
    })
  ),
  tissuesForChart: ({ tissues, gene }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/tissue/${tissues}/${gene}`,
    })
  ),
  validateFormula: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/data/validateFormula/',
      params: data.params,
    })
  ),
  ranking: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/ranking',
      data: data.post,
      params: data.params,
    })
  ),
  search: ({ post, params }) => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/search/',
      data: post,
      params,
    })
  ),
  exactConceptSearch: params => (
    axiosInstance({
      method: 'GET',
      url: 'internal/cerebrum/search/exact',
      params,
    })
  ),
  exactConceptSearchSts: params => (
    axiosInstance({
      method: 'GET',
      url: 'internal/cerebrum/search/exact-st',
      params,
    })
  ),
  getSearchWithSetsData: ({ data, type, params }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/search/publication-set/${type}`,
      data,
      params,
    })
  ),
  exportSearchWithSetsData: ({ data, type, params }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/search/publication-set/${type}/export`,
      data,
      params,
    })
  ),
  getPublicationsCountByYear: params => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/search/pub-count-by-year',
      params,
    })
  ),
  conceptCards: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/search/concept-cards',
      data: data.post,
      params: data.params,
    })
  ),
  categoriesTabData: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/search/categories-tab-data',
      data: data.post,
      params: data.params,
    })
  ),
  searchDatabase: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/search/database-results',
      data: data.post,
      params: data.params,
    })
  ),
  concept: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/concept/${data.id}`,
    })
  ),
  conceptName: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/concept/name/${data}`,
    })
  ),
  conceptCategoryAnalysis: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/concept/actions/category-analysis/${data.id}`,
    })
  ),
  publication: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/publication',
      data: data.post,
    })
  ),
  pubmedPublication: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/publication/pubmed/${data.id}`,
    })
  ),
  publicationTriples: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/publication/triple-info/${data.publicationId}`,
      params: data.params,
    })
  ),
  getPublications: ({ type, params }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/user-publications/${type}`,
      params,
    })
  ),
  deletePublications: ({ type, id, publicationIds }) => (
    axiosInstance({
      method: 'delete',
      url: `/internal/cerebrum/user-publications/${type}`,
      data: publicationIds,
      params: { id },
    })
  ),
  getAllPublicationsIdsByProject: ({ type, id }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/user-publications/${type}/get-all`,
      params: { id },
    })
  ),
  getPublicationsDataForExport: ({ id, type, publicationsIds }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/user-publications/export/${type}`,
      params: { id },
      data: publicationsIds,
    })
  ),
  savePublicationsToProject: ({ id, type, publicationIds }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/user-publications/${type}`,
      params: { id },
      data: publicationIds,
    })
  ),
  pathwayTargetCandidates: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/pathway-target-candidates',
      data: data.post,
      params: data.params,
    })
  ),
  compoundTargetCandidates: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/compound-target-candidates',
      data: data.post,
      params: data.params,
    })
  ),
  phenotypeTargetCandidates: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/phenotype-target-candidates',
      data: data.post,
      params: data.params,
    })
  ),
  disorderTargetCandidates: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/disorder-target-candidates',
      data: data.post,
      params: data.params,
    })
  ),
  categoryTargetCandidates: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/category-target-candidates',
      data: data.post,
      params: data.params,
    })
  ),
  metaboliteTargetCandidates: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/set-analysis/metabolite-target-candidates',
      data: data.post,
      params: data.params,
    })
  ),
  barChart: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/data/tissues',
      data: data.post,
    })
  ),
  getTaxonomies: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/classification/taxonomies',
      data: data.post,
    })
  ),
  getMainTaxonomies: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/classification/main-taxonomies',
      data,
    })
  ),
  getTriples: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/mindmap/get-triples-deprecated',
      data: data.post,
    })
  ),
  getTriplesByConceptIds: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/mindmap/get-triples',
      data: data.post,
    })
  ),
  conceptSuggest: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/concept/suggest',
      params: data.params,
    })
  ),
  getAllRelationMapItems: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/relation-map/',
    })
  ),
  getAllProjectAllRelationMapItems: projectId => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/project-relation-map/${projectId}`,
    })
  ),
  saveRelationMapManagementItem: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/relation-map/',
      data,
    })
  ),
  saveProjectRelationMapManagementItem: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/project-relation-map',
      data,
    })
  ),
  deleteRelationMapManagementItems: ({ ids }) => (
    axiosInstance({
      method: 'delete',
      url: 'internal/cerebrum/relation-map/',
      data: ids,
    })
  ),
  deleteProjectRelationMapManagementItems: ({ ids, projectId }) => (
    axiosInstance({
      method: 'delete',
      url: `/internal/cerebrum/project-relation-map/${projectId}`,
      data: ids,
    })
  ),
  getRelationMapManagementItemById: ({ mapId }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/relation-map/${mapId}`,
    })
  ),
  getProjectRelationMapManagementItemById: ({ mapId, projectId }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/project-relation-map/${projectId}/map/${mapId}`,
    })
  ),
  resultSet: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/result-set/a',
      data: data.post,
      params: data.params,
    })
  ),
  getTripleIdsBetweenNewConcepts: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/mindmap/getTripleIdsBetweenNewConcepts',
      data,
    })
  ),
  getShortConceptsDetails: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/concept/get-short-details',
      data: data.post,
    })
  ),
  getFullSetByCategory: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/result-set/full-set/category-analysis/${data.id}`,
      params: data.params,
    })
  ),
  resultSetAB: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/result-set/ab',
      data: data.post,
      params: data.params,
    })
  ),
  getFullSetForInderectPaths: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/result-set/full-set/getIndirectPaths',
      params: data.params,
    })
  ),
  resultSetABC: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/result-set/abc',
      data: data.post,
      params: data.params,
    })
  ),
  semanticCategoriesForFilter: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/filter/semantic-categories',
      data: data.post,
    })
  ),
  semanticCategoriesTypesForFilter: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/filter/semantic-types',
      data: data.post,
    })
  ),
  predicatesForFilter: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/filter/predicates',
      data: data.post,
    })
  ),
  taxonomiesForFilter: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/filter/taxonomies',
      data: data.post,
    })
  ),
  findLinkedConceptsResultSet: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/result-set/find-linked',
      data: data.post,
    })
  ),
  resultSetAddB: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/result-set/full-set/addB',
      data: data.post,
    })
  ),
  resultSetAddC: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/result-set/full-set/addC',
      data: data.post,
    })
  ),
  searchByCategory: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/search/category',
      data: data.post,
    })
  ),
  cerebrumSemanticCategories: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/concept/semCategories',
    })
  ),
  cerebrumAccessMappings: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/access-mappings',
    })
  ),
  getAllAnalytics: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/analytics/',
    })
  ),
  getAllProjectAnalytics: projectId => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/project-analytics/${projectId}`,
    })
  ),
  deleteAnalytics: data => (
    axiosInstance({
      method: 'delete',
      url: 'internal/cerebrum/analytics/',
      data,
    })
  ),
  deleteProjectAnalytics: ({ ids, projectId }) => (
    axiosInstance({
      method: 'delete',
      url: `internal/cerebrum/project-analytics/${projectId}`,
      data: ids,
    })
  ),
  saveAnalytics: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/analytics/',
      data: data.post,
    })
  ),
  saveProjectAnalytics: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/project-analytics/',
      data: data.post,
    })
  ),
  initAnalytics: ({ id }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/analytics/${id}`,
    })
  ),
  initProjectAnalytics: ({ id, projectId }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/project-analytics/${projectId}/analytics/${id}`,
    })
  ),
  duplicateAnalyticsItem: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/project-analytics/duplicate',
      params: data,
    })
  ),
  duplicateAnalyticsItemV2: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/project-analytics/duplicate/v2',
      params: data,
    })
  ),
  getHeatMapData: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/analytics/matrix',
      data: data.post,
    })
  ),
  getHeatMapForNetworkAnalysis: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/cellular-network-analysis/matrix',
      data: data.post,
    })
  ),
  exportHeatMap: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/analytics/matrix/export',
      data: data.post,
    })
  ),
  geneClassSearchFullListResult: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/search/classification-full-result-list/${data}`,
    })
  ),
  activateUser: data => (
    axiosInstance({
      method: 'get',
      url: 'user/activateAccount',
      params: data.params,
    })
  ),
  geneDiseaseIntro: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/intro',
      data: data.post,
    })
  ),
  geneDiseaseExpressions: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/expressions',
      data: data.post,
    })
  ),
  diffeentialExpression: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/differential-expressions',
      data: data.post,
    })
  ),
  geneDiseaseGeneMutation: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/genetic-mutation',
      data: data.post,
    })
  ),
  geneDiseaseAnimalModel: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/animal-model',
      data: data.post,
    })
  ),
  geneDiseaseMetabolites: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/metabolites',
      data: data.post,
    })
  ),
  geneDiseasePhenotypeAnalysis: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/phenotype',
      data: data.post,
    })
  ),
  geneDiseasePathwayAnalysis: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/pathway',
      data: data.post,
    })
  ),
  geneDiseaseCellMolecularDysfunction: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/dysfunction',
      data: data.post,
    })
  ),
  geneDiseaseOrganTissueFunction: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/organ-tissue-func',
      data: data.post,
    })
  ),
  geneDiseaseConclusion: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/conclusion',
      data: data.post,
    })
  ),
  geneDiseaseAnnotations: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/gene-disease/annotations',
      data: data.post,
    })
  ),
  updateSetConfig: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/advanced-set-management/complex-set/update-config',
      data,
    })
  ),
  getAllSets: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/advanced-set-management/get-all',
    })
  ),
  getSimpleOrEffectSetContent: id => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/advanced-set-management/simple-or-effect-set/${id}`,
    })
  ),
  getComplexSetContent: ({ id, params }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/advanced-set-management/complex-set/content/${id}`,
      params,
    })
  ),
  getComplexSetConfig: id => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/advanced-set-management/complex-set/config/${id}`,
    })
  ),
  checkSimpleSetDataFromComplexSet: ({ id, requestBody }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/advanced-set-management/complex-set/simple-set/${id}`,
      data: requestBody,
    })
  ),
  checkEffectSetDataFromComplexSet: ({ id, requestBody }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/advanced-set-management/complex-set/effect-set/${id}`,
      data: requestBody,
    })
  ),
  saveSimpleOrEffectSetFromComplexSet: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/advanced-set-management/simple-or-effect-set/save',
      data,
    })
  ),
  searchGenes: data => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/data/searchGenes',
      params: data,
    })
  ),
  topUsedTags: data => (
    axiosInstance({
      method: 'get',
      url: '/user/get-top-used-tags',
      params: data,
    })
  ),
  buildNetworkPath: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/cellular-network-analysis/build-paths',
      data: data.post,
      params: data.params,
    })
  ),
  applyComplexSetFilter: ({ data, id }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/advanced-set-management/complex-set/filter/${id}`,
      data,
    })
  ),
  applyComplexSetAggregation: ({ data, id }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/advanced-set-management/complex-set/aggregate/${id}`,
      data,
    })
  ),
  discardComplexSetFilters: id => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/advanced-set-management/complex-set/discard-contents-changes/${id}`,
    })
  ),
  saveFilteredSet: id => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/advanced-set-management/complex-set/save-contents-changes/${id}`,
    })
  ),
  getPathDetails: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/cellular-network-analysis/build-path-details',
      data: data.post,
    })
  ),
  getPathDetailsSmallMolecules: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/cellular-network-analysis/get-small-molecules',
      data: data.post,
    })
  ),
  getAsSet: ({ post, columnToSave }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/cellular-network-analysis/get-as-set/${columnToSave}`,
      data: post,
    })
  ),
  geneDetailDefinition: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/gene-definition`,
    })
  ),
  geneDetailExpression: ({ id }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${id}/gene-expression`,
    })
  ),
  geneDetailHPAData: ({ id }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${id}/hpa`,
    })
  ),
  geneDetailCPTACData: ({ id }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${id}/cptac`,
    })
  ),
  selectedTissuesExpression: ({ id, selectedTissues }) => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/workflows/${id}/gene-expression`,
      data: selectedTissues,
    })
  ),
  geneDetailExpressionWithLabel: ({ id, label }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${id}/gene-expression`,
      params: { label },
    })
  ),
  geneProteinStructure: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/structure`,
    })
  ),
  geneDetailsSimilarProteins: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/similar-proteins`,
    })
  ),
  geneDetailsOrthologs: id => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${id}/orthologs`,
    })
  ),
  geneDetailFunctionalCharacterization: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/gene-rif-bar-chart`,
    })
  ),
  geneDetailFunctionalCharacterizationTable: data => (
    axiosInstance({
      method: 'post',
      url: `internal/cerebrum/gene-details/${data.id}/gene-rifs`,
      params: data.params,
      data: data.filter,
    })
  ),
  geneDetailConditionOfDysregulation: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/conditions-of-dysregulation`,
    })
  ),
  getTherapeuticCandidatesData: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/therapeutic-candidates`,
    })
  ),
  getPatents: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${data.id}/patents`,
      params: data.params,
    })
  ),
  getPatentsPatentInfo: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/patent/${data.id}`,
    })
  ),
  getPatentsPatentSequences: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/patent/${data.id}/amino-acids`,
      params: data.params,
    })
  ),
  getPatentsPatentChemicals: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/patent/${data.id}/chemicals`,
      params: data.params,
    })
  ),
  getPhenotypesData: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${data.id}/phenotypes`,
      params: data.params,
    })
  ),
  geneDetailClinicalTrials: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/clinical-trials`,
    })
  ),
  getGeneDetailsDisorders: data => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${data.id}/disorders`,
    })
  ),
  getGeneDetailsReactomes: ({ geneId, ...params }) => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/gene-details/${geneId}/reactomes`,
      params,
    })
  ),
  postWorkflows: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/workflows',
      data,
      params: data.params,
    })
  ),
  exportWorkflows: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/workflows/export',
      data: data.requestData,
      params: data.params,
    })
  ),
  getSingleCellDatasets: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/workflows/single-cell/datasets',
    })
  ),
  getSingleCellColors: dataset => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/workflows/single-cell/colours/${dataset}`,
    })
  ),
  getSingleCellMarkers: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/cerebrum/workflows/single-cell/markers',
    })
  ),
  getSingleCellGraphBackground: ({ requestBody, ...params }) => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/workflows/single-cell/background',
      params,
      data: requestBody,
    })
  ),
  getSingleCellMetaData: params => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/workflows/single-cell/metadata',
      params,
    })
  ),
  getSingleCellGeneData: ({ geneId, params, requestBody: data }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/workflows/single-cell/gene/${geneId}`,
      params,
      data,
    })
  ),
  getBackgroundCells: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/set-analysis/background/cells',
    })
  ),
  getCellLinesSelectionData: params => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/cell-line-selection/cell-lines',
      params
    })
  ),
  getCellLinesConfigurationsData: params => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/cell-line-selection/config',
      params
    })
  ),
  saveCellLinesConfiguration: data => (
    axiosInstance({
      method: 'put',
      url: 'internal/workflow/cell-line-selection/config',
      data,
    })
  ),
  deleteCellLinesConfiguration: id => (
    axiosInstance({
      method: 'delete',
      url: `internal/workflow/cell-line-selection/config/${id}`,
    })
  ),
  getAdditionalDataForConfiguration: id => (
    axiosInstance({
      method: 'get',
      url: `internal/workflow/cell-line-selection/config/${id}`,
    })
  ),
  getLatestConfigurationData: () => (
    axiosInstance({
      method: 'get',
      url: 'internal/workflow/cell-line-selection/config/latest',
    })
  ),
  getExpressionDataForCellLinesResults: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/cell-line-selection/expressions',
      data
    })
  ),
  getExpressionHeatmapDataForCellLinesResults: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/workflow/cell-line-selection/expressions-heatmap',
      data
    })
  ),
  getShortConceptCard: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/concept/card/${data}`,
    })
  ),
  getTissuesBySource: source => (
    axiosInstance({
      method: 'get',
      url: `internal/cerebrum/tissue/expression/${source}`,
    })
  ),
  getConceptsByIdsArr: data => (
    axiosInstance({
      method: 'post',
      url: 'internal/cerebrum/concept/all',
      data,
    })
  ),
  getCellLines: ({ id, group, scale }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${id}/gene-expression/${group}`,
      params: { scale },
    })
  ),
  getProteinLocation: geneId => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${geneId}/protein-location`,
    })
  ),
  getConcepts: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/search/filtered/',
      data,
    })
  ),
  getFilteredIntermedieteConcepts: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/search/filtered/intermediate-filtered',
      data,
    })
  ),
  getSources: ({ data, type }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/search/filtered/sources/${type}`,
      data,
    })
  ),
  getGeneSetFilterOptions: ({ requestData, prefix }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/search/filtered/${prefix}`,
      data: requestData,
    })
  ),
  categorySearchFullListResult: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/search/filtered/full',
      data: data.post,
    })
  ),
  getCreateSetData: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/create-set-page/for-left-right-set-input',
      data: data.post,
    })
  ),
  getAvailableFilters: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/search/filtered/available-filters',
      params: data.params,
    })
  ),
  duplicateSet: data => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/clone/sets/duplicate',
      params: data.params,
    })
  ),
  getSetsList: data => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/advanced-set-management/all',
      params: data.params,
    })
  ),
  getPDBEntries: data => (
    axios.get(`https://www.ebi.ac.uk/pdbe/graph-api/uniprot/best_non_overlapping_structures/${data}`)
  ),
  getPDBEntriesSummary: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/gene-details/pdb-structure-mappings',
      data,
    })
  ),
  getPublicationCitation: data => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/publication/${data}/citation`,
    })
  ),
  getGeneDetailsExternalLinks: geneId => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${geneId}/external-links`,
    })
  ),
  getGeneDetailsInternalLinks: geneId => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${geneId}/internal-links`,
    })
  ),
  getGeneDetailPopupChartsData: ({
    id,
    type,
    scale,
    group,
    relatedId,
    tumorLabel,
  }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${id}/gene-expression/${type}/${relatedId}`,
      params: { group, label: tumorLabel, scale },
    })
  ),
  getGeneExpressionForMultipleRelated: ({
    geneId,
    type,
    scale,
    sampleExpressionFilterDto,
  }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/gene-details/${geneId}/gene-expression/samples`,
      data: sampleExpressionFilterDto,
      params: { type, scale },
    })
  ),
  getGeneCcleExpressionForMultipleRelated: ({
    geneId,
    scale,
    cancerCategory,
    sampleExpressionFilterDto,
  }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${geneId}/gene-expression/ccle`,
      data: sampleExpressionFilterDto,
      params: { cancerCategory, scale },
    })
  ),
  getCellTypeClusterData: ({ selectedCluster, page, size })  => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/hpa/tissues/cluster/${selectedCluster}`,
      params: { page, size },
    })
  ),
  getGeneDetailsAntibodiesLinks: geneId => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/gene-details/${geneId}/antibodies`,
    })
  ),
  getIntermediateConcepts: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/search/filtered/intermediate',
      data,
    })
  ),
  getRelationMapConceptsPublications: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/mindmap/concepts-publications',
      data,
    })
  ),
  getRelationMapCloselyRelatedPubs: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/mindmap/concepts-closely-publications',
      data,
    })
  ),
  getCompoundImagesData: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/additional-data/compound-images',
      data,
    })
  ),
  getAllMyProjects: ({ params }) => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/my-projects/',
      params,
    })
  ),
  createProject: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/shared-project/',
      data,
    })
  ),
  getUserByDomain: () => (
    axiosInstance({
      method: 'get',
      url: 'user/by-domain',
    })
  ),
  getAllProjects: params => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/shared-project',
      params,
    })
  ),
  deleteProject: id => (
    axiosInstance({
      method: 'delete',
      url: `/internal/cerebrum/shared-project/${id}`,
    })
  ),
  getProject: id => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/shared-project/${id}`,
    })
  ),
  editProject: ({ id, data }) => (
    axiosInstance({
      method: 'put',
      url: `/internal/cerebrum/shared-project/${id}`,
      data,
    })
  ),
  getProjectSets: ({ projectId, params }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/project-set/${projectId}`,
      params,
    })
  ),
  addProjectSet: data => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/project-set/',
      data,
    })
  ),
  getProjectSet: ({ projectId, setId }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/project-set/${projectId}/set/${setId}`,
    })
  ),
  deleteProjectSet: ({ projectId, setIds }) => (
    axiosInstance({
      method: 'delete',
      url: `/internal/cerebrum/project-set/${projectId}`,
      data: setIds,
    })
  ),
  editProjectSet: data => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/project-set/${data.projectId}/set/${data.id}`,
      data,
    })
  ),
  duplicateProjectSets: params => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/project-set/duplicate',
      params,
    })
  ),
  duplicateProjectSetsV2: params => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/project-set/duplicate/v2',
      params,
    })
  ),
  getProjectComplexSetContent: ({ setId, projectId, params }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/content/${setId}`,
      params,
    })
  ),
  getProjectComplexSetConfig: ({ setId, projectId }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/config/${setId}`,
    })
  ),
  checkSimpleSetDataFromProjectComplexSet: ({ setId, projectId, requestBody }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/simple-set/${setId}`,
      data: requestBody,
    })
  ),
  checkEffectSetDataFromProjectComplexSet: ({ setId, projectId, requestBody }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/effect-set/${setId}`,
      data: requestBody,
    })
  ),
  applyProjectComplexSetFilter: ({ data, setId, projectId }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/content/filter/${setId}`,
      data,
    })
  ),
  saveProjectFilteredSet: ({ setId, projectId }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/complex-set/save-contents-changes/${setId}`,
    })
  ),
  updateProjectSetConfig: ({ projectId, ...data }) => (
    axiosInstance({
      method: 'put',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/config`,
      data,
    })
  ),
  discardProjectComplexSetFilters: ({ setId, projectId }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/complex-set/discard-contents-changes/${setId}`,
    })
  ),
  applyProjectComplexSetAggregation: ({ data, setId, projectId }) => (
    axiosInstance({
      method: 'post',
      url: `/internal/cerebrum/project/${projectId}/advanced-set/content/aggregate/${setId}`,
      data,
    })
  ),
  getItemsOfProject: params => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/my-projects/items/${params.myProjectId}`,
      params,
    })
  ),
  getSearchPuplicationsIds: ({ post, params }) => (
    axiosInstance({
      method: 'post',
      url: '/internal/cerebrum/search/pub-ids',
      data: post,
      params,
    })
  ),
  getMajorSemanticCategories: () => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/category/major-categories',
    })
  ),
  getIndicationFinderDiseases: geneId => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/indication-finder/diseases/${geneId}`,
    })
  ),
  searchGenesForIndicationFinder: data => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/indication-finder/search-genes',
      params: data,
    })
  ),
  conceptToConceptIndirectConcepts: ({ concept1, concept2 }) => (
    axiosInstance({
      method: 'get',
      url: `/internal/cerebrum/association-score/${concept1}/${concept2}`,
    })
  ),
  getAntibodies: params => (
    axiosInstance({
      method: 'get',
      url: '/internal/cerebrum/antibodies/for-concept',
      params,
    })
  ),
};

export default Api;
