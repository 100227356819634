export const ProjectTypes = {
  SIMPLE_SET: 'SIMPLE_SET',
  EFFECT_SET: 'EFFECT_SET',
  COMPLEX_SET: 'COMPLEX_SET',
  ANALYTICS: 'ANALYTICS',
  RELATION_MAP: 'RELATION_MAP'
};

export const projectTypeNames = {
  SIMPLE_SET: 'Simple set',
  EFFECT_SET: 'Effect set',
  COMPLEX_SET: 'Complex set',
  ANALYTICS: 'Analytics',
  RELATION_MAP: 'Relation map'
};

export const setsActionsTypes = {
  COMPARE: 'COMPARE',
  VENN: 'VENN',
  DELETE: 'DELETE',
  INTERSECT: 'INTERSECT',
};

export const iconsTypes = {
  BUILD_HEATMAP: 'BUILD_HEATMAP',
  ANALYZE: 'ANALYZE',
  DOWNLOAD: 'DOWNLOAD',
  DELETE: 'DELETE',
  FILTER: 'FILTER',
  EDIT: 'EDIT',
  SET_RESULTS_PAGE: 'SET_RESULTS_PAGE',
  SEARCH: 'SEARCH',
  FIND_RELATED: 'FIND_RELATED',
  RANK_SELECTION: 'RANK_SELECTION',
};
