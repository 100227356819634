import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import Heatmap from '../../graphics/Heatmap/Heatmap';
import Error from '../../../components/common/Error/Error';
import Loader from '../../../components/common/Loader/Loader';
// Constants
import { RELATIVE_PATH } from '../../../constantsCommon';
// Store
import {
  getCompareSetsDataAction,
  resetCompareSetsDataAction,
} from './store/reducer';
import {
  getCompareSetsDataSelector,
  getCompareSetsLoadingSelector,
  getCompareSetsErrorSelector,
} from './store/selectors';
// Utils
import { trimText } from '../../Utils/Utils';
// Styles
import './styles.scss';
import { MAX_RECORDS_COUNT } from '../../graphics/Heatmap/constants';

const propTypes = {
  sets: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  error: PropTypes.string,
  getCompareSetsData: PropTypes.func,
  resetCompareSetsData: PropTypes.func,
};

const CompareSetsPage = (props) => {
  const {
    sets,
    error,
    loading,
    getCompareSetsData,
    resetCompareSetsData,
  } = props;
  const navigate = useNavigate();
  const {
    state: {
      ids,
      projectId,
      recordsCount,
      setsNames,
      projectTypes,
    }
  } = useLocation();

  const showHeatMap = recordsCount <= MAX_RECORDS_COUNT;
  const title = setsNames ? `Heat map of "${setsNames[0]}" compared to "${setsNames[1]}"` : '';
  const titleForDownloading = setsNames ? `Heat map of ${trimText(setsNames[0], 50)} \ncompared to ${trimText(setsNames[1], 50)}` : '';

  useEffect(() => {
    if (ids && projectId) {
      getCompareSetsData({ ids, projectId, projectTypes });
    } else {
      navigate(`${RELATIVE_PATH}/reserch`);
    }
  }, [getCompareSetsData, ids, navigate, projectId]);

  useEffect(() => resetCompareSetsData, []);

  return (
    <div className="compare-sets-page">
      {
        sets.length > 0 &&
        <Heatmap
          showHeatMap={showHeatMap}
          title={title}
          projectId={projectId}
          titleForDownloading={titleForDownloading}
        />
      }
      <Error
        show={error && !loading}
        error={error}
      />
      <Loader isLoading={loading} />
    </div>
  );
};

CompareSetsPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    sets: getCompareSetsDataSelector(state),
    error: getCompareSetsErrorSelector(state),
    loading: getCompareSetsLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getCompareSetsData(data) {
      dispatch(getCompareSetsDataAction(data));
    },
    resetCompareSetsData(data) {
      dispatch(resetCompareSetsDataAction(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompareSetsPage);
