import React from 'react';
import PropTypes from 'prop-types';

// Components
import ModalComponent from '../../../../ModalComponent/ModalComponent';
// Styles
import './styles.scss';
import ProjectsManagement from '../../ProjectsManagement';


const propTypes = {
  data: PropTypes.instanceOf(Object),
  formValues: PropTypes.instanceOf(Object),
  projectUsers: PropTypes.instanceOf(Array),
  getProjectUsersData: PropTypes.func,
  getProjectData: PropTypes.func,
  activeProjectId: PropTypes.string,
  userNameFilter: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  closeCb: PropTypes.func,
  onSubmit: PropTypes.func,
  isAdminRights: PropTypes.bool,
  filterProjectsManagementData: PropTypes.func,
};

const ProjectManagementModal = (props) => {
  const {
    isOpen,
    closeCb,
  } = props;


  if (!isOpen) {
    return null;
  }

  return (
    <ModalComponent
      isOpen={isOpen}
      closeCb={closeCb}
      modalClassName="projects-modal"
    >
      <ProjectsManagement
        toggleProjectsManagementDialog={closeCb}
      />
    </ModalComponent>
  );
};

ProjectManagementModal.propTypes = propTypes;

export default ProjectManagementModal;
