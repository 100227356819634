import React, { useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form/immutable';

// Components
import Error from '../../../../common/Error/Error';
import AddTag from '../../../../common/AddTag/AddTag';
import AddTagContent from '../../../../common/AddTag/AddTagContent';
import UploadFile from '../../../../common/UploadFile/UploadFile';
import Button from '../../../../common/Buttons/Button/Button';
import FormInput from '../../../../common/FormElements/FormInput';
import FormSelect from '../../../../common/FormElements/FormSelect';
import FormTextarea from '../../../../common/FormElements/FormTextarea';
import ConceptSearchField from '../../../../Concept/ConceptSearchForm/ConceptSearchField';
// Store
import * as s from '../../store/selectors';
import * as a from '../../store/actions';
import { setSearchConceptInputValueAction } from '../../../../Concept/ConceptSearchModal/store/actions';
import { getProjectsOptionsAction, resetProjectsOptionsAction } from '../../../../Projects/ProjectsTabs/store/reducer';
import { getProjectsOptionsSelector, getProjectsLoadingSelector } from '../../../../Projects/ProjectsTabs/store/selectors';
// Utils
import { SAVE_AS_SET_FORM } from '../../constants';
import { CONCEPT_SEARCH_FORM_FIELD } from '../../../../Concept/ConceptSearchForm/constants';
// Constants
import { RELATIVE_PATH } from '../../../../../constantsCommon';
// Styles
import './styles.scss';

const propTypes = {
  formValues: PropTypes.instanceOf(Object),
  handleSubmit: PropTypes.func,
  submitting: PropTypes.bool,
  change: PropTypes.func,
  tags: PropTypes.instanceOf(Array),
  updateAddSetTags: PropTypes.func,
  projects: PropTypes.instanceOf(Array),
  projectId: PropTypes.string,
  projectsLoading: PropTypes.bool,
  getProjectsOptions: PropTypes.func,
  resetProjectsOptions: PropTypes.func,
  setShowConceptSearchModal: PropTypes.func,
  setSearchConceptInputValue: PropTypes.func,
  saveButtonDisabled: PropTypes.bool,
  initialize: PropTypes.func,
  navigate: PropTypes.func,
  withFileUpload: PropTypes.bool,
  isComplexSet: PropTypes.bool,
  uploadOnlySimple: PropTypes.bool,
  defaultValues: PropTypes.instanceOf(Object),
  uploadOnlyByDefaultCategory: PropTypes.bool,
  showAddConcept: PropTypes.bool,
  cancelAction: PropTypes.func,
};

const SaveAsSetForm = (props) => {
  const {
    formValues,
    handleSubmit,
    navigate,
    submitting,
    change,
    initialize,
    tags,
    updateAddSetTags,
    projects,
    projectId,
    projectsLoading,
    getProjectsOptions,
    resetProjectsOptions,
    setShowConceptSearchModal,
    setSearchConceptInputValue,
    saveButtonDisabled,
    withFileUpload,
    isComplexSet,
    uploadOnlySimple,
    defaultValues,
    uploadOnlyByDefaultCategory,
    showAddConcept,
    cancelAction,
  } = props;

  const defaultProjectsValue = useMemo(() => {
    if (!projects || !projects.length) return {};
    return projects.find(p => p.id === projectId) || projects[0];
  }, [projects, projectId]);

  useEffect(() => {
    getProjectsOptions();
    return resetProjectsOptions;
  }, []);

  useEffect(() => {
    initialize({
      project: defaultProjectsValue,
    });
  }, [defaultProjectsValue]);

  const handleSearchConceptsClick = useCallback((e) => {
    e.preventDefault();
    setSearchConceptInputValue(formValues[CONCEPT_SEARCH_FORM_FIELD]);
    setShowConceptSearchModal(true);
  }, [formValues, setSearchConceptInputValue, setShowConceptSearchModal]);

  const resetConceptsField = useCallback(() => {
    change(CONCEPT_SEARCH_FORM_FIELD, '');
  }, [change]);

  const cancelFunction = () => {
    if (cancelAction) {
      cancelAction();
    } else {
      navigate(`${RELATIVE_PATH}/my-projects`, {state: {redirectedProjectId: projectId}});
    }
  };

  return (
    <form className="save-as-set-form" onSubmit={handleSubmit}>
      <div className="save-as-set-form__wrap">
        <div className="save-as-set-form__input">
          <div className="save-as-set-form__label">
            Set name
            <span className="mandatory-span">
              <sup>*</sup>
            </span>
          </div>
          <Field
            id="name"
            name="name"
            placeholder="Name"
            component={FormInput}
            type="text"
          />
        </div>
        {
          showAddConcept &&
          <div className="save-as-set-form__input">
            <div className="save-as-set-form__label">
              Add concept
            </div>
            <ConceptSearchField
              reset={resetConceptsField}
              inputValue={formValues ? formValues[CONCEPT_SEARCH_FORM_FIELD] : ''}
              onClick={handleSearchConceptsClick}
            />
          </div>
        }
        <div className="save-as-set-form__input">
          <div className="save-as-set-form__label">
            Add tag
          </div>
          <AddTag
            tagList={tags}
            updateTags={updateAddSetTags}
          />
        </div>
        {
          tags && tags.length > 0 &&
          <div className="save-as-set-form__input">
            <div className="save-as-set-form__label">
              Tags
            </div>
            <AddTagContent
              tagList={tags}
              updateTags={updateAddSetTags}
            />
          </div>
        }
        <div className="save-as-set-form__input">
          <div className="save-as-set-form__label">
            Select project
          </div>
          {
            !projectsLoading && projects &&
            <Field
              name="project"
              options={projects}
              component={FormSelect}
              returnOption={true}
              selectedValue={defaultProjectsValue}
            />
          }
        </div>
        <div className="save-as-set-form__input">
          <div className="save-as-set-form__label">
            Set description
          </div>
          <Field
            id="description"
            name="description"
            placeholder="Description"
            component={FormTextarea}
            type="text"
            rows="5"
          />
        </div>
        {
          withFileUpload &&
          <div className="save-as-set-form__input">
            <div className="save-as-set-form__label">
              Upload file
            </div>
            <UploadFile
              projectId={projectId}
              defaultValues={defaultValues}
              uploadOnlySimple={uploadOnlySimple}
              customClass="save-as-set-form__file-upload"
              callbackAction={a.getSaveAsSetConceptsFromFileAction}
              uploadOnlyByDefaultCategory={uploadOnlyByDefaultCategory}
            />
          </div>
        }
        {
          uploadOnlySimple &&
          <Error
            show={isComplexSet}
            error="You have uploaded Complex set, please change it to Simple set"
          />
        }
        <div className="save-as-set-form__controls">
          <Button
            text="Cancel"
            customClassName="save-as-set-form__btn"
            onClick={cancelFunction}
          />
          <Button
            type="submit"
            text="Save"
            customClassName="save-as-set-form__btn button-primary"
            disabled={submitting || saveButtonDisabled}
          />
        </div>
      </div>
    </form>
  );
};

SaveAsSetForm.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    tags: s.getSaveAsSetTagsSelector(state),
    formValues: s.getSaveAsSetFormValuesSelector(state),
    projects: getProjectsOptionsSelector(state),
    projectsLoading: getProjectsLoadingSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateAddSetTags(data) {
      dispatch(a.updateSaveAsSetTagsAction(data));
    },
    getProjectsOptions() {
      dispatch(getProjectsOptionsAction());
    },
    resetProjectsOptions() {
      dispatch(resetProjectsOptionsAction());
    },
    setSearchConceptInputValue(data) {
      dispatch(setSearchConceptInputValueAction(data));
    },
  };
}

function validate(values) {
  const errors = {};
  if (!values.get('name') || values.get('name').trim() === '') {
    errors.name = 'Enter name';
  }
  return errors;
}

const composition = compose(
  reduxForm({
    form: SAVE_AS_SET_FORM,
    touchOnChange: true,
    destroyOnUnmount: false,
    fields: ['name', 'project'],
    validate,
  }),
  connect(mapStateToProps, mapDispatchToProps)
);

export default composition(SaveAsSetForm);
