import React from 'react';
import PropTypes from 'prop-types';
import DropdownMenu from 'react-dd-menu';
import { connect } from 'react-redux';
import { IoMdAdd } from 'react-icons/io';
// Store
import { createProjectMenuOpenAction, createProjectMenuCloseAction } from './reducer';
import { getMenuOpenKey } from './selectors';
// Styles
import './ProjectCreateDropdown.scss';
import Button from '../../../../common/Buttons/Button/Button';

const propTypes = {
  open: PropTypes.bool,
  menuOpen: PropTypes.func,
  menuClose: PropTypes.func,
  changePassword: PropTypes.func,
  logout: PropTypes.func,
  helpIsOpen: PropTypes.string,
  setHelpIsOpen: PropTypes.func,
  isEnabled: PropTypes.bool,
  addSet: PropTypes.func,
  addMap: PropTypes.func,
  addAnalytics: PropTypes.func,
};

const ProjectCreateDropdown = (props) => {
  const {
    open,
    menuOpen,
    menuClose,
    isEnabled,
    addSet,
    addMap,
    addAnalytics,
  } = props;

  function toggle() {
    if (open) {
      menuClose();
    } else {
      menuOpen();
    }
  }

  const menuOptions = {
    isOpen: open,
    close: menuClose,
    toggle: (
      <div onClick={toggle} className="user-block">
        <Button
          customClassName="management__create-button button-primary"
          disabled={isEnabled}
        >
          <IoMdAdd size={28} />
          Create
        </Button>
      </div>
    ),
    align: 'left',
    textAlign: 'left',
  };

  return (
    <div>
      <DropdownMenu {...menuOptions}>
        <li>
          <span
            className="drop-down-menu-item not-clickable-item"
            onClick={addSet}
          >
            Set
          </span>
        </li>
        <li>
          <span
            className="drop-down-menu-item"
            onClick={addMap}
          >
            Relation Map
          </span>
        </li>
        <li>
          <span
            className="drop-down-menu-item"
            onClick={addAnalytics}
          >
           Analytics
          </span>
        </li>
      </DropdownMenu>
    </div>
  );
};

ProjectCreateDropdown.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    open: getMenuOpenKey(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuOpen() {
      dispatch(createProjectMenuOpenAction());
    },
    menuClose() {
      dispatch(createProjectMenuCloseAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCreateDropdown);
