import { takeLatest, call, put } from 'redux-saga/effects';

import Api from '../../../../Api/Api';

import {
  buildHeatmapAddConceptsAction,
  getBuildHeatmapDataAction,
  setBuildHeatmapDataAction,
  throwBuildHeatmapErrorAction
} from './reducer';
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';
import {ProjectTypes} from '../../../Projects/MyProjectsPage/enum';

function* getBuildHeatmapDataWorker(action) {
  try {
    const { id, projectId, projectType } = action.payload;
    const isProjectTab = checkProjectTab(projectId);

    if (projectType && projectType === ProjectTypes.RELATION_MAP) {
      const apiToCall = isProjectTab ?
        Api.getProjectRelationMapManagementItemById :
        Api.getRelationMapManagementItemById;
      const requestData = isProjectTab ? { mapId: id, projectId } : { mapId: id };
      const { data } = yield call(apiToCall, requestData);
      const ids = data.concepts.map(concept => concept.conceptId);
      const mapData = {
        ...data,
        items: data.concepts.map(concept => ({
          id: concept.conceptId,
          name: concept.name,
          color: concept.color
        })),
      };
      mapData.concepts = null;
      yield put(setBuildHeatmapDataAction({ data: mapData, ids }));
    } else {
      const apiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
      const requestData = isProjectTab ? { setId: id, projectId } : id;
      const { data } = yield call(apiToCall, requestData);
      const ids = data.items.map(concept => concept.id);
      yield put(setBuildHeatmapDataAction({ data, ids }));

      if (data.creatingData && data.creatingData.leftConcepts) {
        const leftConceptIds = data.creatingData.leftConcepts.map(c => c.id);
        yield put(buildHeatmapAddConceptsAction({data, leftConceptIds}));
      }
    }

  } catch (e) {
    yield put(throwBuildHeatmapErrorAction(e.message));
  }
}

function* newOptionSetSaga() {
  yield takeLatest(getBuildHeatmapDataAction, getBuildHeatmapDataWorker);
}

export default newOptionSetSaga;
