import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

// Store
import * as SELECTORS from './store/selectors';
import * as ACTIONS from './store/reducer';
// Styles
import './styles.scss';
import {isFreeUserSelector} from '../../Header/selectors';
import {IoMdAdd} from 'react-icons/io';

const propTypes = {
  redirectToTheProject: PropTypes.bool,
  getProjectsData: PropTypes.func,
  loading: PropTypes.bool,
  projects: PropTypes.instanceOf(Array),
  activeProjectId: PropTypes.string,
  handleProjectClick: PropTypes.func,
  setActiveProjectId: PropTypes.func,
  clearProjectsData: PropTypes.func,
  clearActiveProjectId: PropTypes.func,
  hideProjects: PropTypes.bool,
  createProjectAction: PropTypes.func,
};

const ProjectsTabs = (props) => {
  const {
    loading,
    projects,
    activeProjectId,
    handleProjectClick,
    setActiveProjectId,
    getProjectsData,
    clearProjectsData,
    hideProjects,
    createProjectAction,
  } = props;
  const { state } = useLocation();

  useEffect(() => {
    if (state?.redirectedProjectId) {
      setActiveProjectId(state.redirectedProjectId);
    }
    if (!loading) {
      clearProjectsData();
      getProjectsData();
    }
  }, []);

  function handleClick(id) {
    if (handleProjectClick) {
      handleProjectClick(id);
    }
    setActiveProjectId(id);
  }

  return (
    <div className="tabs-wrapper">
      {
        projects && projects.map((tab, idx) => (
          <div
            key={idx}
            className={classNames({
              'tab-link': true,
              'tab-link_active': tab.id === activeProjectId,
            })}
            onClick={() => handleClick(tab.id)}
          >
            <div className="tab-text">
              {tab.name}
            </div>
          </div>
        ))
      }
      {
        !hideProjects &&
        <div
          key="create-project-tab-key"
          className={classNames({
            'tab-link': true,
          })}
          onClick={createProjectAction}
        >
          <div className="tab-text">
            <IoMdAdd size={28} />
          </div>
        </div>
      }
    </div>
  );
};

ProjectsTabs.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    projects: SELECTORS.getProjectsSelector(state),
    loading: SELECTORS.getProjectsLoadingSelector(state),
    error: SELECTORS.getProjectsErrorSelector(state),
    hideProjects: isFreeUserSelector(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectsData() {
      dispatch(ACTIONS.getProjectsAction());
    },
    clearProjectsData() {
      dispatch(ACTIONS.clearProjectsDataAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectsTabs);

