import cloneDeep from 'lodash.clonedeep';
import { call, put, all } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import { setVennSetsAction } from '../../../SetsDataSelection/reducer';
import { throwVennSetsErrorAction, toggleVennSetsLoaderAction } from '../../reducer';
import { getUniqConcepts } from '../../../../Utils/Utils';
import { generateSetForAnalysis, initialSets } from '../../../../Analytics/common/SetAnalysis/utils';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';
import {ProjectTypes} from '../../../../Projects/MyProjectsPage/enum';

export function* getAllSetsWorker(action) {
  try {
    yield put(toggleVennSetsLoaderAction(true));

    const { idTypes, projectId } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const setsApiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const mapsApiToCall = isProjectTab ? Api.getProjectRelationMapManagementItemById : Api.getRelationMapManagementItemById;
    const getRequestData = (id, type) => (isProjectTab ?
      type === ProjectTypes.RELATION_MAP ? { mapId: id, projectId } : { projectId, setId: id }
      : type === ProjectTypes.RELATION_MAP ? { mapId: id } : id);
    const data = yield all(idTypes.map(idType => call(
      idType.projectType === ProjectTypes.RELATION_MAP ? mapsApiToCall : setsApiToCall,
      getRequestData(idType.id, idType.projectType)
    )));

    const setsData = data.map(d => d.data).map(d => {
      if (!d.items && d.concepts) {
        d.items = d.concepts.map(c => ({ id: c.conceptId, name: c.name}));
      }
      return d;
    });

    let sets = cloneDeep(initialSets);
    setsData.forEach((set, idx) => {
      const generatedData = generateSetForAnalysis([set]);
      Object.assign(sets[idx], generatedData);
    });

    sets = sets.filter(set => set.active);

    const selectedIds = [];
    sets.forEach(set => selectedIds.push(...set.data));
    const { checkedSets, dataSelection } = getUniqConcepts(sets, selectedIds);
    yield put(setVennSetsAction({
      sets,
      selectedIds,
      checkedSets,
      dataSelection,
    }));
    yield put(toggleVennSetsLoaderAction(false));
  } catch (e) {
    yield put(throwVennSetsErrorAction(e.message));
    yield put(toggleVennSetsLoaderAction(false));
  }
}

