import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useNavigate, useLocation } from 'react-router-dom';

// Components
import Loader from '../../common/Loader/Loader';
import Error from '../../common/Error/Error';
import VennDiagram from '../../graphics/VennDiagram/VennDiagram';
import SetsDataSelection from '../SetsDataSelection/SetsDataSelection';
import SaveAsSetModal from '../SaveAsSet/Components/SaveAsSetModal/SaveAsSetModal';
// Constants
import { RELATIVE_PATH } from '../../../constantsCommon';
// Store
import { getSetsVennDiagramData, selectedDataForAnalysis } from '../SetsDataSelection/selectors';
import { getVennSetsAction, clearVennDiagramAction } from './reducer';
import * as SELECTORS from './selectors';
// Styles
import './styles.scss';

const propTypes = {
  loading: PropTypes.bool,
  setsVennDiagramData: PropTypes.instanceOf(Object),
  getVennSets: PropTypes.func,
  analyseData: PropTypes.instanceOf(Array),
  error: PropTypes.string,
  clearVennDiagram: PropTypes.func,
};

const SetVennDiagramPage = (props) => {
  const {
    getVennSets,
    loading,
    error,
    setsVennDiagramData,
    analyseData,
    clearVennDiagram,
  } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, checkedSets } = location.state || {};

  const [addSetPopup, setAddSetPopup] = useState({ show: false, ids: null });

  useEffect(() => {
    if (checkedSets && projectId) {
      const idTypes = checkedSets.map(set => ({id: set.id, projectType: set.projectType}) );
      getVennSets({ idTypes, projectId });
    } else {
      navigate({
        pathname: `${RELATIVE_PATH}/my-projects`,
      });
    }
    return clearVennDiagram;
  }, []);

  const openAddSetPopup = () => {
    setAddSetPopup({
      show: true,
      ids: analyseData.map(item => (
        item.id
      )),
    });
  };

  const closeAddSetPopup = () => {
    setAddSetPopup({
      show: false,
      ids: null,
    });
  };

  const setDiagramCls = classNames({
    'set-diagram': true,
    'set-diagram--small': setsVennDiagramData.data.length < 5,
    'set-diagram--loading': loading,
  });

  return (
    <div className={setDiagramCls}>
      {
        !loading && !error &&
        <div>
          <div className="set-diagram__content flex-grid justify-content-center">
            <div className="col-1">
              <VennDiagram
                vennData={setsVennDiagramData}
              />
            </div>
            <div className="col-2">
              <SetsDataSelection />
              <div className="set-diagram__info-block">
                <span className="set-diagram__concept-count">
                  {`Concept selected: ${analyseData.length}`}
                </span>
                <button
                  onClick={openAddSetPopup}
                  disabled={analyseData && analyseData.length === 0}
                  className="button button-primary set-diagram__button"
                >
                  Save as set
                </button>
              </div>
            </div>
          </div>
        </div>
      }
      <Loader isLoading={loading && !error} />
      <Error show={error && !loading} error={error} />
      <SaveAsSetModal
        isOpen={addSetPopup.show}
        closeCb={closeAddSetPopup}
        projectId={projectId}
        idsForShortConceptDetails={addSetPopup.ids}
      />
    </div>
  );
};

SetVennDiagramPage.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: SELECTORS.getVennSetsLoading(state),
    error: SELECTORS.getVennSetsError(state),
    setsVennDiagramData: getSetsVennDiagramData(state),
    analyseData: selectedDataForAnalysis(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getVennSets(data) {
      dispatch(getVennSetsAction(data));
    },
    clearVennDiagram() {
      dispatch(clearVennDiagramAction());
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetVennDiagramPage);

