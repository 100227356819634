import { createSelector } from 'reselect';

import { sort } from '../../Utils/Utils';

const setsManagementData = state => state.getIn(['myProjects', 'data']);
const setsManagementLoading = state => state.getIn(['myProjects', 'loading']);
const setsManagementError = state => state.getIn(['myProjects', 'error']);
const setsManagementDataSorting = state => state.getIn(['myProjects', 'sorting']);
const setsManagementDataFilters = state => state.getIn(['myProjects', 'filters']);
const values = state => state.getIn(['form', 'COPY_MY_PROJECT', 'values']);
const setsManagementFilteringSet = state => state.getIn(['myProjects', 'set']);

const projects = state => state.getIn(['projectsTabsReducer', 'projects', 'data']);
const activeProjectId = state => state.getIn(['myProjects', 'activeProjectId']);

export const getSetsManagementDataSelector = createSelector(
  setsManagementData,
  setsManagementDataSorting,
  setsManagementDataFilters,
  (data, sorting, filter) => {
    if (!data) return [];

    const { titleFilter, tagFilter } = filter.toJS();
    const sorted = sort(data, sorting.toJS());
    const sortedProjects = sorted && sorted.toJS();
    let filteredProjects = sortedProjects;

    if (titleFilter) {
      const filterValueLow = titleFilter.toLowerCase();
      filteredProjects = filteredProjects.filter(p => (
        p.name.toLowerCase().includes(filterValueLow)
      ));
    }

    if (tagFilter) {
      const filterValueLow = tagFilter.toLowerCase();
      filteredProjects = filteredProjects.filter(p =>
        p.tags.filter(t => t.toLowerCase().includes(filterValueLow)).length
      );
    }

    return filteredProjects;
  }
);

export const getSetsManagementLoadingSelector = createSelector(
  setsManagementLoading,
  data => data
);

export const getSetsManagementErrorSelector = createSelector(
  setsManagementError,
  data => data
);

export const getSetsManagementDataFiltersSelector = createSelector(
  setsManagementDataFilters,
  data => data
);

export const getSetsManagementDataSortingSelector = createSelector(
  setsManagementDataSorting,
  data => data && data.toJS()
);

export const getCheckedSetsSelector = createSelector(
  [getSetsManagementDataSelector],
  sets => (sets ? sets.filter(set => set.selected === true) : [])
);

export const copySetSelector = createSelector(
  values,
  data => data && data.toJS()
);

export const getFilteringSet = createSelector(
  setsManagementFilteringSet,
  data => data && data.toJS()
);

export const getActiveProjectIdSelector = createSelector(
  activeProjectId,
  data => data
);

export const activeProjectDataSelector = createSelector(
  projects,
  activeProjectId,
  (_data, _activeProjectId) => {
    const data = _data && _data.toJS();
    return data.find(item => item.id === _activeProjectId);
  }
);
export const getIsProjectLockedSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED'
);

export const getIsProjectLockedForUsersSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.status === 'LOCKED_FOR_USERS'
);

export const getIsProjectPublicSelector = createSelector(
  [activeProjectDataSelector],
  data => data && data.public
);
