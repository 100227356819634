import { takeLatest, call, put, all } from 'redux-saga/effects';

import Api from '../../../../Api/Api';
import { setHeatmapInitialDataAction } from '../../../graphics/Heatmap/store/reducer';
import {
  getCompareSetsDataAction,
  setCompareSetsDataAction,
  throwCompareSetsErrorAction,
} from './reducer';
import { formatHeatmapInitialData } from '../utils';
import { checkProjectTab } from '../../../Projects/ProjectsTabs/utils';
import {ProjectTypes} from '../../../Projects/MyProjectsPage/enum';

function* getCompareSetsDataWorker(action) {
  try {
    const { ids, projectId, projectTypes } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const setApiToCall = isProjectTab ? Api.getProjectSet : Api.getSimpleOrEffectSetContent;
    const mapApiToCall = isProjectTab ? Api.getProjectRelationMapManagementItemById : Api.getRelationMapManagementItemById;
    const getRequestData = (id, type) => (isProjectTab ?
      type === ProjectTypes.RELATION_MAP ? { mapId: id, projectId } : { projectId, setId: id }
      : type === ProjectTypes.RELATION_MAP ? { mapId: id } : id);

    const data = yield all(ids.map((id, index) => call(
      projectTypes[index] === ProjectTypes.RELATION_MAP ? mapApiToCall : setApiToCall,
      getRequestData(id, projectTypes[index])
    )));

    const sets = data.map(d => d.data).map(d => {
      if (!d.items && d.concepts) {
        d.items = d.concepts.map(c => ({ id: c.conceptId, name: c.name}));
      }
      return d;
    });
    yield put(setCompareSetsDataAction(sets));
    yield put(setHeatmapInitialDataAction(formatHeatmapInitialData(sets)));
  } catch (e) {
    yield put(throwCompareSetsErrorAction(e.message));
  }
}

function* compareSetsSaga() {
  yield takeLatest(getCompareSetsDataAction, getCompareSetsDataWorker);
}

export default compareSetsSaga;
