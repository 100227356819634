import { call, put } from 'redux-saga/effects';

// Api
import Api from '../../../../../Api/Api';
// Utils
import { RELATIVE_PATH } from '../../../../../constantsCommon';
import { buildSearchLink } from '../../../../Search/utils';
// Store
import { throwSetManagementDataErrorAction } from '../../reducer';

export function* myProjectManagementSearchRedirectWorker({ payload }) {
  try {
    const { data } = yield call(Api.getItemsOfProject, payload);

    const linkValues = data.reduce((result, item) => ({
      plainValue: result.plainValue ? `${result.plainValue} AND ${item.name}` : `${item.name}`,
      exactValue: result.exactValue ? `${result.exactValue} AND ${item.id}` : `${item.id}`,
    }), { plainValue: '', exactValue: '' });

    const link = buildSearchLink(linkValues);

    window.open(`${RELATIVE_PATH}${link.pathname}?${link.search}`, '_blank');
  } catch (e) {
    yield put(throwSetManagementDataErrorAction, e.message);
  }
}
