import { call, put } from 'redux-saga/effects';

import Api from '../../../../../Api/Api';
import { checkProjectTab } from '../../../../Projects/ProjectsTabs/utils';
import {
  putMyProjectsDataAction,
  toggleSetManagementDataLoaderAction,
  throwSetManagementDataErrorAction,
} from '../../reducer';

export function* getSetManagementDataWorker(action) {
  try {
    yield put(toggleSetManagementDataLoaderAction(true));
    const { projectId } = action.payload;
    const isProjectTab = checkProjectTab(projectId);
    const requestData = isProjectTab ? projectId : null;
    const result = yield call(Api.getAllMyProjects, {
      params: {
        projectId: requestData,
      },
    });
    yield put(putMyProjectsDataAction(result.data));
  } catch (e) {
    yield put(throwSetManagementDataErrorAction(e.message));
    yield put(toggleSetManagementDataLoaderAction(false));
  }
}
