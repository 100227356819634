import React from 'react';

// Styles
import './styles.scss';

const Venn3DiagramIcon = () => (
  <i className="venn3-diagram-icon" />
);

export default Venn3DiagramIcon;
