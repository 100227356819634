import { call, put, select } from 'redux-saga/effects';

// Api
import Api from '../../../../../Api/Api';
// Store
import { getMyProjectManagementDataAction, throwSetManagementDataErrorAction } from '../../reducer';
import { copySetSelector } from '../../selectors';
import { getProjectIdForRequest } from '../../../../Projects/ProjectsTabs/utils';
import { getProjectsOptionsSelector } from '../../../../Projects/ProjectsTabs/store/selectors';
import {ProjectTypes} from '../../enum';

const typeSetConst = 'SET';

export function* duplicateSetManagementWorker({ payload }) {
  try {
    const { idTypes, projectId, multiselect } = payload;
    const { name, project = {} } = yield select(copySetSelector);
    const options = yield select(getProjectsOptionsSelector);
    const defaultProject = options.some(({ id }) => id === projectId) ? projectId : null;
    const targetProject = project.id || defaultProject;

    const groupedIds = idTypes.reduce((acc, { id, projectType }) => {
      let entityType = projectType;
      if (projectType === ProjectTypes.SIMPLE_SET || projectType === ProjectTypes.EFFECT_SET || projectType === ProjectTypes.COMPLEX_SET)
        entityType = typeSetConst;

      if (!acc[entityType]) {
        acc[entityType] = []; // Initialize an empty array if the type doesn't exist
      }
      acc[entityType].push(id); // Add the id to the array for the given type
      return acc;
    }, {});

    for (const [type, ids] of Object.entries(groupedIds)) {
      const requestData = {
        targetProject: getProjectIdForRequest(targetProject),
        'ids': ids.join(','),
        projectId: getProjectIdForRequest(projectId),
      };
      if (!multiselect) {
        requestData.title = name.trim();
      }

      switch (type) {
        case typeSetConst:
          yield call(Api.duplicateProjectSetsV2, requestData);
          break;
        case ProjectTypes.RELATION_MAP:
          yield call(Api.duplicateRelationMapManagementItemV2, requestData);
          break;
        case ProjectTypes.ANALYTICS:
          yield call(Api.duplicateAnalyticsItemV2, requestData);
          break;
      }
    }

    yield put(getMyProjectManagementDataAction({ projectId }));
  } catch (e) {
    yield put(throwSetManagementDataErrorAction(e.message));
  }
}
