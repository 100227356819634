import { PERSONAL_TAB } from '../../Projects/ProjectsTabs/constants';

export function getSearchWithSetsRequestData(projectId, sets, type, sort) {
  const { sortBy, sortDirection } = sort;
  return {
    data: {
      idTypes: sets.map(s => ({id: s.id, type: s.projectType})),
      ...(projectId !== PERSONAL_TAB && { projectId }),
    },
    type,
    ...(!!sortBy && {
      params: { sort: `${sortBy},${sortDirection}` },
    }),
  };
}
