import {
  call,
  put,
  takeEvery,
  select,
} from 'redux-saga/effects';

// Api
import Api from '../../../Api/Api';
// Utils
import { history } from '../../../index';
import { RELATIVE_PATH } from '../../../constantsCommon';
import { mapAndFormatConceptsToSave } from '../../Sets/SaveAsSet/utils';
// Store
import { updateSaveAsSetConceptsAction } from '../../Sets/SaveAsSet/store/actions';
import { selectedDataForAnalysis } from '../../Sets/SetsDataSelection/selectors';
import {
  GENES_DETAIL_REQUESTED,
  GENES_DETAIL_SUCCEEDED,
  GENES_DETAIL_FAILED,
  GENE_DETAIL_SHORT_CONCEPTS_DETAILS_FAILED,
  SAVE_NEW_SET_FROM_ANALYTICS,
} from './constants';
import {
  getApi,
  getConcept,
  getTotalElements,
  getAnalyticsGenes,
} from './selectors';

function* pathwaySaga(action) {
  try {
    const apiKey = yield select(getApi);
    const {
      connectedConcepts,
      id,
    } = yield select(getConcept);
    const analyticsGenes = yield select(getAnalyticsGenes);

    const defaultParams = {
      allGenes: false,
      size: 20,
    };

    const {
      data,
    } = action;

    const params = {
      ...defaultParams,
      page: data && data.page ? data.page : 0,
    };

    const requestData = {
      params,
      post: {
        candidateGi: id,
        conceptGis: analyticsGenes || connectedConcepts,
      },
    };

    const response = yield call(Api[apiKey], requestData);
    yield put({ type: GENES_DETAIL_SUCCEEDED, data: response.data });
  } catch (e) {
    yield put({ type: GENES_DETAIL_FAILED, message: e.message });
  }
}

function* saveNewSetFromAnalyticsSaga(action) {
  const { postAction } = action.data;
  try {
    const apiKey = yield select(getApi);
    const totalElements = yield select(getTotalElements);
    const concept = yield select(getConcept);
    const conceptGis = yield select(selectedDataForAnalysis);
    const requestData = {
      params: {
        allGenes: false,
        size: totalElements,
      },
      post: {
        candidateGi: concept.id,
        conceptGis: conceptGis.map(item => (
          item.id
        )),
      },
    };

    const allItemsResponse = yield call(Api[apiKey], requestData);
    const ids = allItemsResponse.data.content.map(item => item.geneGi);

    const response = yield call(Api.getShortConceptsDetails, { post: ids });

    if (postAction === 'saveAsSet') {
      history.push(`${RELATIVE_PATH}/sets/add/personal`);
      yield put(updateSaveAsSetConceptsAction(mapAndFormatConceptsToSave(response.data)));
    }
  } catch (e) {
    console.log(e);// eslint-disable-line no-console
    yield put({ type: GENE_DETAIL_SHORT_CONCEPTS_DETAILS_FAILED, message: e.message });
    if (postAction === 'analyze') {
      history.push(`${RELATIVE_PATH}/my-projects`);
    }
  }
}

function* setAnalysisGenesDetail() {
  yield takeEvery(GENES_DETAIL_REQUESTED, pathwaySaga);
  yield takeEvery(SAVE_NEW_SET_FROM_ANALYTICS, saveNewSetFromAnalyticsSaga);
}

export default setAnalysisGenesDetail;
